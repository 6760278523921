import React from 'react';
import 'containers/ContactUs/styles.scss';

const ContactUs = () => {
    return (
        <div className="page-container">
            <h1>Contact Us</h1>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdh5d_YT7eDzpcaghMKYpuPFR39WBjfwtmX3lC1JZ03HZFN3A/viewform?embedded=true" width="100%" height="900" title="Contact Us">Loading…</iframe>
        </div>
    );
};

export default ContactUs;
