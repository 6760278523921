import React from 'react';
import 'containers/AboutUs/OurPeople/style.scss';
import content from 'utils/constants/content.json';
const OurPeople = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.ourPeoplePage.title}</h1>
            <div className="our-people-container">



                <div className="people-left">
                    <img className="people-img" src={content.ourPeoplePage.person1Image} alt="Priyanshu Belwal" />
                    <h2 className="teamtext"><b>{content.ourPeoplePage.person1Name}</b></h2>
                    <span><p className="team">{content.ourPeoplePage.person1details}</p></span>
                </div>



                <div className="people-right">
                    <img className="people-img2" src={content.ourPeoplePage.person2Image} alt="Saurabh Tiwari" />
                    <h2 className="teamtext"><b>{content.ourPeoplePage.person2Name}</b></h2>
                    <span><p className="team">{content.ourPeoplePage.person2details}</p></span>
                </div>

            </div>
            {/* <p className="page-paragraph">{content.overview.paraOne}</p> */}
        </div>
    );
};

export default OurPeople;
