import React from 'react';
import 'containers/AboutUs/C2RWorkingModel/style.scss';
import content from 'utils/constants/content.json';
const C2RWorkingModel = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.workingModel.heading}</h1>
            <div className="working-model-Image-container">
                <img className="working-model-Image" src="/images/working-model/1.png" alt="C2R Working Model" />
                <img className="working-model-Image" src="/images/working-model/2.png" alt="C2R Working Model" />
                <img className="working-model-Image" src="/images/working-model/3.png" alt="C2R Working Model" />
                <img className="working-model-Image" src="/images/working-model/4.png" alt="C2R Working Model" />
                <img className="working-model-Image" src="/images/working-model/5.png" alt="C2R Working Model" />
                <img className="working-model-Image" src="/images/working-model/6.png" alt="C2R Working Model" />

            </div>
        </div>
    );
};

export default C2RWorkingModel;
