import React from 'react';
import 'containers/AboutUs/VisionAndMission/style.scss';
import content from 'utils/constants/content.json';
const VisionAndMission = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.visionAndMission.headingOne}</h1>
            <p className="page-paragraph">{content.visionAndMission.paraOne1}</p>
            <li className="page-paragraph-il">{content.visionAndMission.paraOne2}</li>
            <li className="page-paragraph-il">{content.visionAndMission.paraOne3}</li>
            <li className="page-paragraph-il">{content.visionAndMission.paraOne4}</li>

            <h1 className="page-heading">{content.visionAndMission.headingTwo}</h1>
            <p className="page-paragraph">{content.visionAndMission.paraTwo1}</p>
            <p className="page-paragraph">{content.visionAndMission.paraTwo2}</p>

            <li className="page-paragraph-il">{content.visionAndMission.paraTwo3}</li>
            <li className="page-paragraph-il">{content.visionAndMission.paraTwo4}</li>
            <li className="page-paragraph-il">{content.visionAndMission.paraTwo5}</li>
            <li className="page-paragraph-il">{content.visionAndMission.paraTwo6}</li>



        </div>
    );
};

export default VisionAndMission;
