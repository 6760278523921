import React from 'react';
import 'containers/OurWork/WomenEmpowerment/style.scss';
import content from 'utils/constants/content.json';
const WomenEmpowerment = () => {
  return (
    <div className="page-container">
      <h1 className="page-heading">{content.womenEmpowermentPage.title}</h1>
      <h2 className="page-heading">{content.womenEmpowermentPage.heading1}</h2>
      <p className="page-paragraph">{content.womenEmpowermentPage.paraOne1}</p>
      <p className="page-paragraph">{content.womenEmpowermentPage.paraOne2}</p>
      <h2 className="page-heading">{content.womenEmpowermentPage.heading2}</h2>
      <p className="page-paragraph">{content.womenEmpowermentPage.paratwo1}</p>
      <p className="page-paragraph">{content.womenEmpowermentPage.paratwo2}</p>
      <p className="page-paragraph">{content.womenEmpowermentPage.paratwo3}</p>

    </div>
  );
};

export default WomenEmpowerment;
