import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './app.scss';
import 'antd/dist/antd.css';
import { paths } from 'utils/constants/paths';

import Layout from 'containers/Layout';
import Home from 'containers/Home';
import Team from 'containers/AboutUs/Team';
import Education from 'containers/OurWork/Education';
import Health from 'containers/OurWork/Health';
import SkillDevelopment from 'containers/OurWork/SkillDevelopment';
import WomenEmpowerment from 'containers/OurWork/WomenEmpowerment';
import Overview from 'containers/AboutUs/Overview';
import VisionAndMission from 'containers/AboutUs/VisionAndMission';
import C2RStories from 'containers/AboutUs/C2RStories';
import C2RWorkingModel from 'containers/AboutUs/C2RWorkingModel';
import ReachAndPresence from 'containers/AboutUs/ReachAndPresence';
import Impact from 'containers/AboutUs/Impact';
import ChampionsSpeak from 'containers/AboutUs/ChampionsSpeak';
import OurPeople from 'containers/AboutUs/OurPeople';
import TeamMember from 'containers/TeamMember';
import FAQ from 'containers/GetInvolved/FAQ';
import Donate from 'containers/GetInvolved/Donate';
import Volunteer from 'containers/GetInvolved/Volunteer';
import Career from 'containers/GetInvolved/Career';
import Campaigns from 'containers/GetInvolved/Campaigns';
import NGO from 'containers/GetInvolved/NGO';
import School from 'containers/GetInvolved/School';
import College from 'containers/GetInvolved/College';
import ContactUs from 'containers/ContactUs';

const App: React.FC = () => (
  <Router>
    <Layout>
      <Switch>
        <Route exact path={paths.HOME} component={Home} />
        <Route exact path={paths.TEAM} component={Team} />
        <Route exact path={paths.EDUCATIION} component={Education} />
        <Route exact path={paths.HEALTH} component={Health} />
        <Route exact path={paths.WOMEN_EMPOWERMENT} component={WomenEmpowerment} />
        <Route exact path={paths.SKILL_DEVELOPMENT} component={SkillDevelopment} />
        <Route exact path={paths.OVERVIEW} component={Overview} />
        <Route exact path={paths.VISION_AND_MISSION} component={VisionAndMission} />
        <Route exact path={paths.C2R_STORIES} component={C2RStories} />
        <Route exact path={paths.C2R_WORKING_MODEL} component={C2RWorkingModel} />
        <Route exact path={paths.REACH_AND_PRESENCE} component={ReachAndPresence} />
        <Route exact path={paths.IMPACT} component={Impact} />
        <Route exact path={paths.CHAMPIONS_SPEAK} component={ChampionsSpeak} />
        <Route exact path={paths.OUR_PEOPLE} component={OurPeople} />
        <Route exact path={paths.TEAM_MEMBER} component={TeamMember} />
        <Route exact path={paths.FAQ} component={FAQ} />
        <Route exact path={paths.DONATE} component={Donate} />
        <Route exact path={paths.VOLUNTEER} component={Volunteer} />
        <Route exact path={paths.CAREER} component={Career} />
        <Route exact path={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_DONATE} component={Donate} />
        <Route exact path={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_VOLUNTEER} component={Volunteer} />
        <Route exact path={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_DONATE} component={Donate} />
        <Route exact path={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_CAMPAIGNS} component={Campaigns} />
        <Route exact path={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_NGO} component={NGO} />
        <Route exact path={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_SCHOOL} component={School} />
        <Route exact path={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_COLLEGE} component={College} />
        <Route exact path={paths.CONTACT_US} component={ContactUs} />

      </Switch>
    </Layout>
  </Router>
);

export default App;
