import React from 'react';
import 'containers/TeamMember/style.scss';



const TeamMember = (props: any) => {
    console.log(props.location.state)
    return (
        <div className="page-container">
            <div className="team-member-flex">
                <img src={props.location.state.member.imageUrl} alt={props.location.state.member.name} />

            </div>
            <div className="team-member-data">
                <h1 className="page-heading">{props.location.state.member.name}</h1>
                <p>{props.location.state.member.designation}</p>
                <div>
                    <h3>{props.location.state.member.introduction1}</h3>
                    <h3>{props.location.state.member.introduction2}</h3>
                    <h3>{props.location.state.member.introduction3}</h3>
                    <h3>{props.location.state.member.introduction4}</h3>
                    <h3>{props.location.state.member.introduction5}</h3>

                </div>
            </div>
        </div>
    );
};

export default TeamMember;
