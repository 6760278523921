import React from 'react';
import 'containers/GetInvolved/Campaigns/style.scss';
import content from 'utils/constants/content.json';
const Campaigns = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{'Campaings'}</h1>
            <h1 className="page-paragraph">{content.campaignesPage.para1}</h1>
        </div>
    );
};

export default Campaigns;