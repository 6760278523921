import React from 'react';
import 'containers/GetInvolved/College/style.scss';
import content from 'utils/constants/content.json';
const College = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{'Colleges'}</h1>
            <h1 className="page-paragraph">{content.schoolcollegePage.para1}</h1>
            <h1 className="page-paragraph">{content.schoolcollegePage.para2}</h1>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6EbovzYfMuXllYMEk4eHGK3c4Ylh2Q5lS3ury4kWFMCC0KQ/viewform?embedded=true" width="100%" height="1538" title="College">Loading…</iframe>
        </div>
    );
};

export default College;
