import React from 'react';
import { Link } from 'react-router-dom';
import 'components/WorkAreas/workareas.scss';
import content from 'utils/constants/content.json';
import { paths } from 'utils/constants/paths';

const WorkAreas = () => {
  return (
    <div className="work-areas-container">
      <div className="work-area-heading">{content.homePage.workAreasHeading}</div>
      <div className="areas-container">
        {content.homePage.workAreas.map((area) => (
          <Link to={`${paths.EDUCATIION}`}>
            <div className={area.classes}>
              <div className="area-box-content">
                <div className="area-title">{area.title}</div>
                {/* <div className="area-description ">{area.descryption}</div> */}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default WorkAreas;
