import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button, Drawer } from 'antd';
import 'components/Navbar/navbar.scss';
import { paths } from 'utils/constants/paths';

const { SubMenu } = Menu;

class Navbar extends Component {
  state = {
    active: 'home',
    current: 'mail',
    visible: false
  };
  handleClick = (e: any) => {
    console.log('click ', e);
    this.setState({
      active: e.key
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <Fragment>
        <div className="horizontal-menu">
          <Menu onClick={this.handleClick} selectedKeys={[this.state.active]} mode="horizontal" style={{ borderBottom: '0px' }}>
            <Menu.Item key="home">
              <Link to={paths.HOME}>HOME</Link>
            </Menu.Item>

            <SubMenu key="about" title={<span>ABOUT US</span>}>
              <Menu.Item key="about1">
                <Link to={paths.OVERVIEW}>Overview</Link>
              </Menu.Item>

              <Menu.Item key="about2">
                <Link to={paths.VISION_AND_MISSION}>Vision & Mission </Link>
              </Menu.Item>

              <Menu.Item key="about3">
                <Link to={paths.TEAM}>C2R Team</Link>
              </Menu.Item>

              <Menu.Item key="about4">
                <Link to={paths.C2R_STORIES}> C2R Campaigns </Link>
              </Menu.Item>

              <Menu.Item key="about5">
                <Link to={paths.C2R_WORKING_MODEL}> C2R Working Model </Link>
              </Menu.Item>

              {/* <Menu.Item key="about6">
                <Link to={paths.REACH_AND_PRESENCE}>Reach & Presence </Link>
            </Menu.Item> */}

              <Menu.Item key="about7">
                <Link to={paths.IMPACT}>Impact</Link>
              </Menu.Item>

              {/* <Menu.Item key="about8">
                <Link to={paths.CHAMPIONS_SPEAK}> Champions Speak </Link>
            </Menu.Item> */}
              <Menu.Item key="about9">
                <Link to={paths.OUR_PEOPLE}> Our People </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="aop" title={<span>OUR WORK</span>}>
              <Menu.Item key="aop:1">
                <Link to={paths.EDUCATIION}>Education</Link>
              </Menu.Item>
              <Menu.Item key="aop:2">
                <Link to={paths.SKILL_DEVELOPMENT}>Skill Development</Link>
              </Menu.Item>
              <Menu.Item key="aop:3">
                <Link to={paths.HEALTH}>Health</Link>
              </Menu.Item>
              <Menu.Item key="aop:4">
                <Link to={paths.WOMEN_EMPOWERMENT}>Women Empowerment</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="getInvalve" title={<span>GET INVOLVED</span>}>
              <SubMenu key="getInvalve1" title="Individual Support">
                <Menu.Item key="1">
                  <Link to={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_DONATE}>Donate</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_VOLUNTEER}>Volunteer</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="getInvalve2" title="Corporate Partnerships">

                <Menu.Item key="3">
                  <Link to={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_DONATE}>Donate</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_CAMPAIGNS}>Campaigns</Link>

                </Menu.Item>
              </SubMenu>
              <SubMenu key="getInvalve3" title="Institutional Alliances">
                <Menu.Item key="5">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_NGO}>NGOs</Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_SCHOOL}>School</Link>
                </Menu.Item>
                <Menu.Item key="7">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_COLLEGE}>College</Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="getInvalve4">
                <Link to={paths.FAQ}>FAQ</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="donate" className="donte">
              <Link to={paths.DONATE}>DONATE</Link>
            </Menu.Item>
            {/* <Menu.Item>REGISTER</Menu.Item>
                    <Menu.Item>LOGIN</Menu.Item> */}
          </Menu>
        </div>
        <Button className="barsMenu" onClick={this.showDrawer}>
          <img src="/icons/menu-icon.webp" alt="Menu-Icon" />
        </Button>
        <Drawer

          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Menu onClick={this.handleClick} selectedKeys={[this.state.active]} >
            <Menu.Item key="home">
              <Link to={paths.HOME}>HOME</Link>
            </Menu.Item>

            <SubMenu key="about" title={<span>ABOUT US</span>}>
              <Menu.Item key="about1">
                <Link to={paths.OVERVIEW}>Overview</Link>
              </Menu.Item>

              <Menu.Item key="about2">
                <Link to={paths.VISION_AND_MISSION}>Vision & Mission </Link>
              </Menu.Item>

              <Menu.Item key="about3">
                <Link to={paths.TEAM}>C2R Team</Link>
              </Menu.Item>

              <Menu.Item key="about4">
                <Link to={paths.C2R_STORIES}> C2R Campaigns </Link>
              </Menu.Item>

              <Menu.Item key="about5">
                <Link to={paths.C2R_WORKING_MODEL}> C2R Working Model </Link>
              </Menu.Item>

              <Menu.Item key="about6">
                <Link to={paths.REACH_AND_PRESENCE}>Reach & Presence </Link>
              </Menu.Item>

              <Menu.Item key="about7">
                <Link to={paths.IMPACT}>Impact</Link>
              </Menu.Item>

              <Menu.Item key="about8">
                <Link to={paths.CHAMPIONS_SPEAK}> Champions Speak </Link>
              </Menu.Item>
              <Menu.Item key="about9">
                <Link to={paths.OUR_PEOPLE}> Our People </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="aop" title={<span>OUR WORK</span>}>
              <Menu.Item key="aop:1">
                <Link to={paths.EDUCATIION}>Education</Link>
              </Menu.Item>
              <Menu.Item key="aop:2">
                <Link to={paths.SKILL_DEVELOPMENT}>Skill Development</Link>
              </Menu.Item>
              <Menu.Item key="aop:3">
                <Link to={paths.HEALTH}>Health</Link>
              </Menu.Item>
              <Menu.Item key="aop:4">
                <Link to={paths.WOMEN_EMPOWERMENT}>Women Impoverment</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu key="getInvalve" title={<span>GET INVOLVED</span>}>
              <SubMenu key="getInvalve1" title="Individual Support">
                <Menu.Item key="1">
                  <Link to={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_DONATE}>Donate</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to={paths.GET_INVOLVED_INDIVIDUAL_SUPPORT_VOLUNTEER}>Volunteer</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="getInvalve2" title="Corporate Partnerships">

                <Menu.Item key="3">
                  <Link to={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_DONATE}>Donate</Link>
                </Menu.Item>
                <Menu.Item key="4">
                  <Link to={paths.GET_INVOLVED_COORPORATE_PARTNERSHIP_CAMPAIGNS}>Campaigns</Link>

                </Menu.Item>
              </SubMenu>
              <SubMenu key="getInvalve3" title="Institutional Alliances">
                <Menu.Item key="5">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_NGO}>NGOs</Link>
                </Menu.Item>
                <Menu.Item key="6">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_SCHOOL}>School</Link>
                </Menu.Item>
                <Menu.Item key="7">
                  <Link to={paths.GET_INVOLVED_INSTITUTIONAL_ALLIANCES_COLLEGE}>College</Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="getInvalve4">
                <Link to={paths.FAQ}>FAQ</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="donate" className="donte">
              <Link to={paths.DONATE}>DONATE</Link>
            </Menu.Item>

            {/* <Menu.Item>REGISTER</Menu.Item>
                    <Menu.Item>LOGIN</Menu.Item> */}
          </Menu>
        </Drawer>
      </Fragment >
    );
  }
}

export default Navbar;
