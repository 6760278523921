import React from 'react';
import 'containers/GetInvolved/Donate/style.scss';
import content from 'utils/constants/content.json';

const Donate = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.donatePage.title}</h1>
            <h1 className="page-paragraph">{content.donatePage.para1}</h1>
            <h1 className="page-paragraph">{content.donatePage.para2}</h1>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScVQyl9P49P6F2o4TdxFsI8KlprsTKRfD2D7BM87h83hfgwkQ/viewform?embedded=true" width="100%" height="1538" title="Donate">Loading…</iframe>
        </div>
    );
};

export default Donate;
