import React from 'react';
import 'components/HomePageTeam/homepageteam.scss';



import content from 'utils/constants/content.json';

import MemberCard from 'components/MemberCard';
import { Link } from 'react-router-dom';
import { paths } from 'utils/constants/paths';



const HomePageTeam = () => {
    return (
        <div>
            <div className="team-homepage-heading-container">
                <h1 className="work-area-heading">Meet Our Team</h1>
            </div>
            <div className="scroll-team">
                {content.team.peoplesLeaderShip.map((member) => (
                    <div className="scroll-team-card">
                        <Link to={{
                            pathname: `${paths.TEAM_MEMBER}`,
                            state: {
                                member: member
                            }
                        }}>
                            < MemberCard key={member.name} {...member} />
                        </Link>
                    </div>
                )
                )}
            </div>
        </div>
    );
};

export default HomePageTeam;
