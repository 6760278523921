import React from 'react';
import 'containers/GetInvolved/FAQ/style.scss';
import content from 'utils/constants/content.json';
const FAQ = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.faqGetInvolved.title}</h1>

            <li className="page-paragraph">{content.faqGetInvolved.paraOneQ}</li>
            <p className="page-paragraph-ans">{content.faqGetInvolved.paraOneA}</p>
            <li className="page-paragraph">{content.faqGetInvolved.paraTwoQ}</li>
            <p className="page-paragraph-ans">{content.faqGetInvolved.paraTwoA}</p>
            <li className="page-paragraph">{content.faqGetInvolved.paraThreeQ}</li>
            <p className="page-paragraph-ans">{content.faqGetInvolved.paraThreeA}</p>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraThreeAA}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraThreeAB}</li>
            <p></p>
            <li className="page-paragraph">{content.faqGetInvolved.paraFourQ}</li>
            <p className="page-paragraph-ans">{content.faqGetInvolved.paraFourA}</p>
            <li className="page-paragraph">{content.faqGetInvolved.paraFiveQ}</li>
            <p className="page-paragraph-ans">{content.faqGetInvolved.paraFiveA}</p>
            <li className="page-paragraph">{content.faqGetInvolved.paraSixQ}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAA}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAB}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAC}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAD}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAE}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAF}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAG}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAH}</li>
            <li className="page-paragraph-il">{content.faqGetInvolved.paraSixAI}</li>

        </div>
    );
};

export default FAQ;
