import React from 'react';
//import 'containers/Education/education.scss';
import content from 'utils/constants/content.json';
const Education = () => {
  return (
    <div className="page-container">
      <h1 className="page-heading">{content.educationPage.title}</h1>
      <h2 className="page-heading">{content.educationPage.heading1}</h2>
      <p className="page-paragraph">{content.educationPage.paraOne}</p>
      <p className="page-paragraph">{content.educationPage.paraTwo}</p>
      <p className="page-paragraph">{content.educationPage.paraThree}</p>
      <p className="page-paragraph">{content.educationPage.paraFour}</p>
      <h2 className="page-heading">{content.educationPage.heading2}</h2>
      <p className="page-paragraph">{content.educationPage.paraFive}</p>
      <li className="page-paragraph-il">{content.educationPage.paraFive1}</li>
      <li className="page-paragraph-il">{content.educationPage.paraFive2}</li>
      <li className="page-paragraph-il">{content.educationPage.paraFive3}</li>
      <li className="page-paragraph-il">{content.educationPage.paraFive4}</li>
      <li className="page-paragraph-il">{content.educationPage.paraFive5}</li>
      <li className="page-paragraph-il">{content.educationPage.paraFive6}</li>
      <br></br>
      <p className="page-paragraph">{content.educationPage.paraSix}</p>
      <li className="page-paragraph-il">{content.educationPage.paraSeven}</li>
      <li className="page-paragraph-il">{content.educationPage.paraSeven}</li>



    </div>
  );
};

export default Education;
