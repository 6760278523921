import React from 'react';
import 'containers/AboutUs/ChampionsSpeak/style.scss';
import content from 'utils/constants/content.json';
const ChampionsSpeak = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.championsSpeak.title}</h1>
        </div>
    );
};

export default ChampionsSpeak;
