import React from 'react';
import { Carousel } from 'antd';
import 'components/Carousel/carousel.scss';

const C2RCarousel = () => {
    return (
        <div className="carousel-body">
            <Carousel autoplay>
                <div>
                    <img src="/images/carousel/01.jpg" alt="Carousel 1" />
                </div>
                <div>
                    <img src="/images/carousel/02.jpg" alt="Carousel 2" />
                </div>
                <div>
                    <img src="/images/carousel/03.jpg" alt="Carousel 3" />
                </div>
                <div>
                    <img src="/images/carousel/04.jpg" alt="Carousel  4" />
                </div>
            </Carousel>
        </div>
    );
};

export default C2RCarousel;
