import React from 'react';
import { Link } from 'react-router-dom';
import { paths } from 'utils/constants/paths';
import 'components/Footer/footer.scss';

import constants from 'utils/constants/content.json';

const Footer = () => {
    return (
        <footer className="footer-body">
            <div className="footer-column">
                <div style={{ width: '70%' }}>
                    <Link to={paths.HOME}>
                        <img className="footerimg" src="/images/footer/2.png" alt="connect2roots" width="100%" />
                    </Link>
                    <div className="footer-column-title2">Registered under Societies Act 1860</div>
                </div>
            </div>
            <div className="footer-column">
                <div className="footer-column-title">{constants.nav.aboutUs}</div>
                <Link to={paths.OVERVIEW}>
                    <div className="footer-links">{constants.nav.overview}</div>
                </Link>
                <Link to={paths.VISION_AND_MISSION}>
                    <div className="footer-links">{constants.nav.visionAndMission}</div>
                </Link>
                <Link to={paths.TEAM}>
                    <div className="footer-links">{constants.nav.team}</div>
                </Link>
                <Link to={paths.IMPACT}>
                    <div className="footer-links">{constants.nav.impact}</div>
                </Link>
            </div>
            <div className="footer-column">
                <div className="footer-column-title">{constants.nav.ourWork}</div>
                <Link to={paths.EDUCATIION}>
                    <div className="footer-links">{constants.nav.education}</div>
                </Link>
                <Link to={paths.HEALTH}>
                    <div className="footer-links">{constants.nav.health}</div>
                </Link>
                <Link to={paths.SKILL_DEVELOPMENT}>
                    <div className="footer-links">{constants.nav.skillDevelopment}</div>
                </Link>
                <Link to={paths.WOMEN_EMPOWERMENT}>
                    <div className="footer-links">{constants.nav.womenEmpowerment}</div>
                </Link>
            </div>
            <div className="footer-column">
                <div className="footer-column-title">{constants.nav.getInvolved}</div>
                <Link to={paths.DONATE}>
                    <div className="footer-links">{constants.nav.donate}</div>
                </Link>
                <Link to={paths.VOLUNTEER}>
                    <div className="footer-links">{constants.nav.volunteer}</div>
                </Link>
                <Link to={paths.FAQ}>
                    <div className="footer-links">{constants.nav.faq}</div>
                </Link>
            </div>
            <div className="footer-column">
                <div className="footer-column-title">OTHER LINKS</div>
                <Link to={paths.CAREER}>
                    <div className="footer-links">{constants.nav.career}</div>
                </Link>

                <Link to={paths.CONTACT_US}>
                    <div className="footer-links">{constants.nav.contactUs}</div>
                </Link>

                <div className="footer-column-title">Social Media Link</div>
                <div className="social-media-container">
                    <div className="social-media-item">
                        <a href={paths.FACEBOOK} target="_blank" rel="noopener noreferrer">
                            <img src="/icons/fb.png" width="42px" alt="facebook" />
                        </a>
                    </div>
                    <div className="social-media-item">
                        <a href={paths.INSTAGRAM} target="_blank" rel="noopener noreferrer">
                            <img src="/icons/insta.png" width="42px" alt="instagram" />
                        </a>
                    </div>
                    <div className="social-media-item">
                        <a href={paths.LINKEDIN} target="_blank" rel="noopener noreferrer">
                            <img src="/icons/in.png" width="42px" alt="linkedin" />
                        </a>
                    </div>
                    <div className="social-media-item">
                        <a href={paths.TWITTER} target="_blank" rel="noopener noreferrer">
                            <img src="/icons/twitter.png" width="42px" alt="There" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
