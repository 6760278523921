import React from 'react';
import Carousel from 'components/Carousel';
import Banner from 'components/Banner';
import WorkAreas from 'components/WorkAreas';
import HomePageTeam from 'components/HomePageTeam';
import 'containers/Home/home.scss';


const Home = () => {
  return (
    <div>
      <Carousel />
      <Banner />
      <WorkAreas />
      <HomePageTeam />
    </div>
  );
};

export default Home;
