export const paths = {
  HOME: '/',

  OVERVIEW: '/about/overview',
  VISION_AND_MISSION: '/about/vision_and_mission',
  TEAM: '/about/team',
  C2R_STORIES: '/about/c2r_stories',
  C2R_WORKING_MODEL: '/about/c2r_working_model',
  IMPACT: '/about/impact',
  REACH_AND_PRESENCE: '/about/react_and_presence',
  CHAMPIONS_SPEAK: '/about/champions_speak',
  OUR_PEOPLE: '/about/our_people',
  FAQ: '/faq',
  DONATE: '/donate',
  VOLUNTEER: '/volunteer',
  GET_INVOLVED_INDIVIDUAL_SUPPORT_DONATE: '/get_involved/individual_support/donate',
  GET_INVOLVED_INDIVIDUAL_SUPPORT_VOLUNTEER: '/get_involved/individual_support/volunteer',
  GET_INVOLVED_COORPORATE_PARTNERSHIP_DONATE: '/get_involved/coorporate_partnership/donate',
  GET_INVOLVED_COORPORATE_PARTNERSHIP_CAMPAIGNS: '/get_involved/coorporate_partnership/campaings',
  GET_INVOLVED_INSTITUTIONAL_ALLIANCES_NGO: '/get_involved/institutional_alliances/ngo',
  GET_INVOLVED_INSTITUTIONAL_ALLIANCES_SCHOOL: '/get_involved/institutional_alliances/school',
  GET_INVOLVED_INSTITUTIONAL_ALLIANCES_COLLEGE: '/get_involved/institutional_alliances/college',
  CONTACT_US: '/contact_us',


  EDUCATIION: '/our_work/education',
  HEALTH: '/our_work/health',
  SKILL_DEVELOPMENT: '/our_work/skill_development',
  WOMEN_EMPOWERMENT: '/our_work/women_empowerment',

  LOGIN: '/login',
  REGISTER: '/register',

  CAREER: '/career',

  FACEBOOK: 'https://www.facebook.com/connect2roots/',
  INSTAGRAM: 'https://www.instagram.com/invites/contact/?i=njg1d5snmq9p&utm_content=h93ruif',
  LINKEDIN: 'https://www.linkedin.com/company/connect2roots-org',
  TWITTER: 'https://twitter.com/Connect2Roots?s=09',
  TEAM_MEMBER: '/about/team/team_member',
};
