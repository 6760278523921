import React from 'react';
import 'containers/AboutUs/Impact/style.scss';
import content from 'utils/constants/content.json';
const Impact = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.impactPage.title}</h1>
            <p className="page-paragraph">{content.impactPage.paraOne1}</p>
            <p className="page-paragraph">{content.impactPage.paraOne2}</p>
            <p className="page-paragraph">{content.impactPage.paraOne3}</p>

            <img className="impactImage" src="/images/impact/1.jpg" alt="C2R Impact" />
            <img className="impactImage" src="/images/impact/2.jpg" alt="C2R Impact" />


        </div>
    );
};

export default Impact;
