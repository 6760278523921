import React from 'react';

import 'components/MemberCard/membercard.scss';
import { IMemberCardProps } from 'components/MemberCard/types';

const MemberCard: React.FC<IMemberCardProps> = ({ name, designation, imageUrl }) => {
    return (
        <div className="card">
            <div className="card-container">
                <img src={imageUrl} className="member-image" alt={name} /> <hr />
                <div className="member-name">{name}</div>
                <br />
                <div className="member-designation">{designation}</div>
                {/* <div className="member-social">
                    {facebookUrl && <a href={facebookUrl}><img src="/icons/fb.png" width="30px" alt="facebook" /></a>}

                    {instagramUrl && <a href={instagramUrl}> <img src="/icons/insta.png" width="30px" alt="instagram" /></a>}

                    {linkedinUrl && <a href={linkedinUrl}><img src="/icons/in.png" width="30px" alt="linkedin" /></a>}

                </div> */}
            </div>
        </div >
    );
};

export default MemberCard;
