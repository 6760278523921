import React from 'react';
import 'containers/AboutUs/C2RStories/style.scss';
import content from 'utils/constants/content.json';
const C2RStories = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{"Connect2Roots Campaigns"}</h1>
            <h2 className="page-heading">{content.c2rstories.heading1}</h2>
            <p className="page-paragraph">{content.c2rstories.paraOne1}</p>
            <p className="page-paragraph">{content.c2rstories.paraOne2}</p>
            <p className="page-paragraph">{content.c2rstories.paraOne3}</p>
            <p className="page-paragraph">{content.c2rstories.paraOne4}</p>
            <p className="page-paragraph">{content.c2rstories.paraOne5}</p>
            <p className="page-paragraph">{content.c2rstories.paraOne6}</p>
            <h2 className="page-heading">{content.c2rstories.heading2}</h2>
            <p className="page-paragraph">{content.c2rstories.paratwo1}</p>
            <p className="page-paragraph">{content.c2rstories.paratwo2}</p>
            <p className="page-paragraph">{content.c2rstories.paratwo3}</p>

        </div>
    );
};

export default C2RStories;
