import React from 'react';
import 'containers/OurWork/Health/health.scss';
import content from 'utils/constants/content.json';
const Health = () => {
  return (
    <div className="page-container">
      <h1 className="page-heading">{content.healthPage.title}</h1>
      <h2 className="page-heading">{content.healthPage.heading1}</h2>
      <p className="page-paragraph">{content.healthPage.paraOne1}</p>
      <p className="page-paragraph">{content.healthPage.paraOne2}</p>
      <p className="page-paragraph">{content.healthPage.paraOne3}</p>
      <p className="page-paragraph">{content.healthPage.paraOne4}</p>
      <p className="page-paragraph">{content.healthPage.paraOne5}</p>
      <h2 className="page-heading">{content.healthPage.heading2}</h2>
      <h2 className="page-paragraph">{content.healthPage.paratwo0}</h2>
      <li className="page-paragraph-il">{content.healthPage.paratwo1}</li>
      <li className="page-paragraph-il">{content.healthPage.paratwo2}</li>
    </div>
  );
};

export default Health;
