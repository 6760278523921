import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './layout.scss';

const Layout = (props: any) => {
    return (
        <div>
            <Header />
            <div className="content">{props.children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
