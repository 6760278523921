import React from 'react';
import 'containers/AboutUs/Team/team.scss';
import content from 'utils/constants/content.json';
import { Link } from 'react-router-dom';
import { paths } from 'utils/constants/paths';

import MemberCard from 'components/MemberCard';

const Team = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.team.headingLeaderShip}</h1>
            <p className="page-paragraph">{content.team.paraLeaderShip}</p>
            <div className="team-group">

                {content.team.peoplesLeaderShip.map((member) => (
                    <Link to={{
                        pathname: `${paths.TEAM_MEMBER}`,
                        state: {
                            member: member
                        }
                    }}>
                        < MemberCard key={member.name} {...member} />
                    </Link>

                )
                )}
            </div>
            <h1 className="page-heading">{content.team.headingAdvisoryPanel}</h1>
            <p className="page-paragraph">{content.team.paraAdvisoryPanel}</p>
            <div className="team-group">

                {content.team.peoplesAdvisoryPanel.map((member) => (
                    <Link to={{
                        pathname: `${paths.TEAM_MEMBER}`,
                        state: {
                            member: member
                        }
                    }}>
                        < MemberCard key={member.name} {...member} />
                    </Link>

                )
                )}
            </div>
            {/* <h1 className="page-heading">{content.team.headingOperationsTeam}</h1>
            <div className="team-group">

                {content.team.peoplesOperationsTeam.map((member) => (
                    <Link to={{
                        pathname: `${paths.TEAM_MEMBER}`,
                        state: {
                            member: member
                        }
                    }}>
                        < MemberCard key={member.name} {...member} />
                    </Link>

                )
                )}
            </div>

            <h1 className="page-heading">{content.team.headingInnovationTeam}</h1>
            <div className="team-group">

                {content.team.peoplesInnovationTeam.map((member) => (
                    <Link to={{
                        pathname: `${paths.TEAM_MEMBER}`,
                        state: {
                            member: member
                        }
                    }}>
                        < MemberCard key={member.name} {...member} />
                    </Link>

                )
                )}
            </div> */}
        </div >
    );
};

export default Team;
