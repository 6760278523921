import React from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import Navbar from 'components/Navbar';
import { paths } from 'utils/constants/paths';

const Header = () => {
    return (
        <div className="main-header">
            <Link to={paths.HOME}>
                <div className="logo-and-title">
                    <img className="logo" src="/c2r-logo.png" alt="c2r logo" />
                    <div className="title"></div>
                </div>
            </Link>
            <Navbar />
        </div>
    );
};

export default Header;
