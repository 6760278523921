import React from 'react';
import 'containers/OurWork/SkillDevelopment/style.scss';
import content from 'utils/constants/content.json';
const SkillDevelopment = () => {
  return (
    <div className="page-container">
      <h1 className="page-heading">{content.skillDevelopmentPage.title}</h1>
      <h2 className="page-heading">{content.skillDevelopmentPage.heading1}</h2>
      <p className="page-paragraph">{content.skillDevelopmentPage.paraOne1}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paraOne2}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paraOne3}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paraOne4}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paraOne5}</p>
      <h2 className="page-heading">{content.skillDevelopmentPage.heading2}</h2>
      <p className="page-paragraph">{content.skillDevelopmentPage.paratwo1}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paratwo2}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paratwo3}</p>
      <p className="page-paragraph">{content.skillDevelopmentPage.paratwo4}</p>

    </div>
  );
};

export default SkillDevelopment;
