import React from 'react';
import content from 'utils/constants/content.json';
import 'components/Banner/banner.scss';
//import { paths } from 'utils/constants/paths';
//import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div className="banner">
      <p>{content.homePage.HomePageContent1}</p>
      <p>{content.homePage.HomePageContent2}</p>
      <p>{content.homePage.HomePageContent3}</p>
      <p>{content.homePage.HomePageContent4}</p>
      {/* <Link to={paths.OVERVIEW}>{'Read More....'}</Link> */}
      <div className="banner2">
        <img className="image1" src="/images/description/1.jpg" alt="abc" />
        {content.homePage.QuoteOfHome}
        <img className="image1" src="/images/description/2.jpg" alt="abc" />
      </div>
    </div>
  );

};

export default Banner;
