import React from 'react';
import 'containers/AboutUs/Overview/style.scss';
import content from 'utils/constants/content.json';
const Overview = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.overview.headingOne}</h1>
            <p className="page-paragraph">{content.overview.paraOne1}</p>
            <p className="page-paragraph">{content.overview.paraOne2}</p>
            <p className="page-paragraph">{content.overview.paraOne3}</p>
            <p className="page-paragraph">{content.overview.paraOne4}</p>

            {/* <h1 className="page-heading">{content.overview.headingTwo}</h1>
            <p className="page-paragraph">{content.overview.paraTwo1}</p>
            <p className="page-paragraph">{content.overview.paraTwo2}</p> */}
        </div>
    );
};

export default Overview;
