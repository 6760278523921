import React from 'react';
import 'containers/AboutUs/ReachAndPresence/style.scss';
import content from 'utils/constants/content.json';
const ReachAndPresence = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.reachAndPresencePage.title}</h1>
            {/* <p className="page-paragraph">{content.overview.paraOne}</p> */}
        </div>
    );
};

export default ReachAndPresence;
