import React from 'react';
import 'containers/GetInvolved/Volunteer/style.scss';
import content from 'utils/constants/content.json';
const Volunteer = () => {
    return (
        <div className="page-container">
            <h1 className="page-heading">{content.volunteerPage.title}</h1>
            <h1 className="page-paragraph">{content.volunteerPage.para1}</h1>
            <h1 className="page-paragraph">{content.volunteerPage.para2}</h1>
            <li className="page-paragraph-il">{content.volunteerPage.para3}</li>
            <ol className="page-paragraph-il">{content.volunteerPage.para4}</ol>
            <ol className="page-paragraph-il">{content.volunteerPage.para5}</ol>
            <li className="page-paragraph-il">{content.volunteerPage.para6}</li>
            <ol className="page-paragraph-il">{content.volunteerPage.para7}</ol>
            <ol className="page-paragraph-il">{content.volunteerPage.para8}</ol>
            <li className="page-paragraph-il">{content.volunteerPage.para9}</li>
            <h1 className="page-paragraph">{content.volunteerPage.para10}</h1>
            <li className="page-paragraph-il">{content.volunteerPage.para11}</li>
            <h1 className="page-paragraph">{content.volunteerPage.para12}</h1>
            <h1 className="page-paragraph">{content.volunteerPage.para13}</h1>
            <li className="page-paragraph-il">{content.volunteerPage.para14}</li>
            <ol className="page-paragraph-il">{content.volunteerPage.para15}</ol>
            <ol className="page-paragraph-il">{content.volunteerPage.para16}</ol>
            <ol className="page-paragraph-il">{content.volunteerPage.para17}</ol>
            <ol className="page-paragraph-il">{content.volunteerPage.para18}</ol>
            <li className="page-paragraph-il">{content.volunteerPage.para19}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para20}</li>
            <h1 className="page-paragraph">{content.volunteerPage.para21}</h1>
            <li className="page-paragraph-il">{content.volunteerPage.para22}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para23}</li>
            <h1 className="page-paragraph">{content.volunteerPage.para24}</h1>
            <li className="page-paragraph-il">{content.volunteerPage.para25}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para26}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para27}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para28}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para29}</li>
            <li className="page-paragraph-il">{content.volunteerPage.para30}</li>



            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfPl8K8vMSd5wpLzoKJeMhvf0Jn4t1IwXnqKKDcfalcVZNovQ/viewform?embedded=true" width="100%" height="1538" title="Volunteer">Loading…</iframe>
        </div>
    );
};

export default Volunteer;
